import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { OverlayPanelConfigProps } from '@components/molecules/next-overlay-panel/enums/next.overlay-panel.enum';
import { CountryConstant } from '@pages/general-maintenance/pages/country/constant/country.constant';
import { RiskRatingsConstant } from '@pages/general-maintenance/pages/country/constant/risk-ratings.constant';
import { CountryFilterProps } from '@pages/general-maintenance/pages/country/enums/country.enum';
import { RiskRatingsProps } from '@pages/general-maintenance/pages/country/enums/risk-ratings.enum';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { GenericObject } from '@utils/models/Types';
import { Observable } from 'rxjs';

@Component({
  selector: 'country-filter',
  templateUrl: './country-filter.component.html',
  styleUrl: './country-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryFilterComponent  implements OnInit {
  @Input() filterValues: GenericObject = {};
  @Input() countriesList: any;
  @Input() riskRatingList: any;
  @Input() titleCriteria?: string;
  @Input() titleButtonCriteria?: string;
  @Input() iconPositionButtonInfo: LinkButtonPosition;
  @Input() countriesRiskRatingDropdown: any;
  @Input() hasExtraFilter: boolean = false;

  @Output() filterChange = new EventEmitter<any>();

  extraFormGroup: FormGroup;
  countryCodeGroup$:Observable<DropdownList>;
  countriesCodeDropdown: any = [];
  countriesRegionsDropdown: any = [];
  countriesEuMemberDropdown: any = [];
  countriesInsuredFDIDropdown: any = [];
  configPanelRiskRating = {
    [OverlayPanelConfigProps.KEY_PROP]: RiskRatingsProps.RISK_RATING_CRITERIA, 
    [OverlayPanelConfigProps.VALUE_PROP]: RiskRatingsProps.FDI_COUNTRY_LIMIT,
    [OverlayPanelConfigProps.CURRENCY_PROP]: RiskRatingsProps.FDI_COUNTRY_CURRENCY,
    [OverlayPanelConfigProps.DESCRIPTION_PROP]: RiskRatingsProps.RISK_RATING_DESCRIPTION
  };

  protected readonly CountryFilterProps = CountryFilterProps;
  protected readonly RiskRatingsConstant = RiskRatingsConstant;
  protected readonly CountryConstant = CountryConstant;
  protected readonly RiskRatingsProps = RiskRatingsProps;
  
  constructor(
    private _fb: FormBuilder,
    private _dropdownService: DropdownService
  ) {}
  
  ngOnInit() {
    this._getDataToFilter();
    this._defineFormGroup();
  }

  private _getDataToFilter() {
    const listCountries = NextValueHelper.defaultValue(this.countriesList, []);
    const countriesCode = new Map();
    const countriesRegions = new Map();
    const countriesEuMember = new Map();
    const countriesInsuredFDI = new Map();

    listCountries.map((country: any) => {
      countriesCode.set(country.code, this._dropdownService.getDropdownItem(country.code,country.code));
      countriesRegions.set(country.operationalRegion, this._dropdownService.getDropdownItem(country.operationalRegion,country.operationalRegion));
      countriesEuMember.set(country.euroIndicator, this._dropdownService.getDropdownItem(country.euroIndicator,country.euroIndicator));
      countriesInsuredFDI.set(country.finishVehicleAccounting, this._dropdownService.getDropdownItem(country.finishVehicleAccounting,country.finishVehicleAccounting));
    });
    
    this.countriesCodeDropdown = Array.from(countriesCode.values());
    this.countriesRegionsDropdown = Array.from(countriesRegions.values());
    this.countriesEuMemberDropdown = Array.from(countriesEuMember.values());
    this.countriesInsuredFDIDropdown = Array.from(countriesInsuredFDI.values());
  }

  private _defineFormGroup() {
    const groupValues = NextFormHelper.buildGroupValues(
      [
        CountryFilterProps.CODE,
        CountryFilterProps.OPERATIONAL_REGION,
        CountryFilterProps.EURO_INDICATOR,
        CountryFilterProps.FINISH_VEHICLE_ACCOUNTING,
        CountryFilterProps.RISK_CODE,
        CountryFilterProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP_FROM,
        CountryFilterProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP_TO,
        CountryFilterProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP_FROM,
        CountryFilterProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP_TO
      ],
      { ...CountryConstant.extraFilterInitialValues, ...this.filterValues }
    );
    this.extraFormGroup = this._fb.group(groupValues);
  }
}
