import { ColorType } from 'app/shared/enums/color.enum';

export class RiskRatingsConstant {
    static readonly colorRiskRatingCriteria: any = {
        'A': ColorType.BLUE,
        'B': ColorType.SUCCESS,
        'C': ColorType.YELLOW,
        'D': ColorType.CAUTION,
        'E': ColorType.DANGER,
    }
}