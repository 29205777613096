import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { FilterBaseComponent } from '@components/organisms/next-table/components/filter-base/filter-base.component';
import { CountryAction, CountryApiProps, CountryEditProps, CountryFilterProps, CountryProps } from './enums/country.enum';
import { CountryService } from './services/country.service';
import { TablePageEvent } from 'primeng/table';
import { CountryConstant } from './constant/country.constant';
import { TableField } from '@components/organisms/next-table/next-table.type';
import { NextBooleanHelper } from '@utils/core/next-boolean.helper';
import { TranslateService } from '@ngx-translate/core';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { forkJoin } from 'rxjs';
import { RiskRatingsProps } from './enums/risk-ratings.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { GenericTableColumnProps } from '@components/organisms/next-table/enums/next-table.enum';
import { MenuItemService } from 'app/core/services/menu-item.service';
import { NextArrayHelper } from '@utils/core/next-array.helper';
import { TaskBoardService } from '@pages/task-board/services/task-board.service';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { RouteParams } from 'app/core/enums/route.enum';
import { TbGeneralMaintenanceApiProps, TbGeneralMaintenanceProps } from '@pages/task-board/enums/tb-general-maintenance.enum';
import { TaskBoardSection } from '@pages/task-board/enums/task-board.enum';
import { DownloadService } from '@services/download.service';
import { NavigationService } from '@services/navigation.service';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';

@Component({
  selector: 'country',
  templateUrl: './country.component.html',
  styleUrl: './country.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryComponent extends FilterBaseComponent implements OnInit {
  @Input() isTaskBoard: boolean = false;

  title = 'COUNTRY.TITLE';
  sortField = CountryProps.NAME
  globalFilterFields = CountryConstant.globalFilter;
  columnsTableCountries: TableField[] = CountryConstant.tableListConfig;
  countriesList: any;
  dataCountry: any;
  dataCountryRef: any;
  riskRatingList: any = [];

  actionIconType = NextIcon.EDIT;
  titleButtonCriteria:string = 'COUNTRY.FIELDS.VIEW_RISK_RATING_CRITERIA';
  titleCriteria: string = 'COUNTRY.FIELDS.RISK_RATING_CRITERIA_AND_FDI_COUNTRY_LIMIT';
  iconPositionButtonInfo = LinkButtonPosition.LEFT;
  activePopup:boolean = false;
  selectedCountry: any;
  dataKey: string = CountryProps.CODE;
  countryCode: number;
  idTask: number
  countriesRiskRatingDropdown: any = [];
  section = TaskBoardSection.GENERAL_MAINTENANCE;

  protected readonly CountryFilterProps = CountryFilterProps;
  protected readonly TbGeneralMaintenanceApiProps = TbGeneralMaintenanceApiProps;
  protected readonly CountryApiProps = CountryApiProps;
  protected readonly CountryConstant = CountryConstant;
  protected readonly CountryProps = CountryProps;

  constructor(
    injector$: Injector,
    private _countryService: CountryService,
    private _cdr: ChangeDetectorRef,
    private _translateService: TranslateService,
    private _menuItemService: MenuItemService,
    private _taskBoardService: TaskBoardService,
    private _downloadService: DownloadService,
    private _navigationService: NavigationService
  ) {
    super(injector$);
    this._cdr = injector$.get(ChangeDetectorRef);
  }

  ngOnInit(): void {
    this.applyFilter();
    if (this.isTaskBoard) {
      this.idTask = NextObjectHelper.getPropertyFromObject(this._activatedRoute.snapshot.params, [RouteParams.ID]);
      forkJoin({
        'dataCountry': this._taskBoardService.getInfoTask(this.idTask),
        'dataRiskRating': this._countryService.getRiskRatingCountries()}).subscribe(result => {
          const countryTask = result.dataCountry
          const mappedRefByRiskRatingCriteria = NextArrayHelper.indexArrayByProperty(result.dataRiskRating, RiskRatingsProps.RISK_RATING_CRITERIA);

          this._countryService.getCountryById(countryTask.affectedResourceId).subscribe(country => {
            this.title = country.name;
            this.countryCode = country.code;
            const dataCountry = result.dataCountry;
            const tbCountry = JSON.parse(dataCountry.jsonPayload);
            this.dataCountry = {
              riskCode: tbCountry.countryRisk,
              insuranceExceptionAmount: tbCountry.insuranceLimitExAmt,
              insuranceExceptionEffectiveTimestamp: tbCountry.insuranceLimitExEffortTimeStamp,
              insuranceExceptionExpiryTimestamp: tbCountry.insuranceLimitExExpiryTimeStamp,
              status: dataCountry.status,
              operationalRegion: country.operationalRegion,
              euroIndicator: country.euroIndicator,
              finishVehicleAccounting: country.finishVehicleAccounting,
              fdiCountryLimit: mappedRefByRiskRatingCriteria[tbCountry.countryRisk].fdiCountryLimit,
              fdiCountryCurrency: mappedRefByRiskRatingCriteria[tbCountry.countryRisk].fdiCountryCurrency,
              insuranceExceptionCurrency: mappedRefByRiskRatingCriteria[tbCountry.countryRisk].fdiCountryCurrency
            }
            this.dataCountryRef = {
              ... country,
              id: country.code,
              fdiCountryLimit: mappedRefByRiskRatingCriteria[country.riskCode].fdiCountryLimit,
              fdiCountryCurrency: mappedRefByRiskRatingCriteria[country.riskCode].fdiCountryCurrency
            }

            this.riskRatingList = result.dataRiskRating;
            
            this._cdr.markForCheck();
        });
        
      });
    } else {
      forkJoin({
        'dataCountry':this._countryService.getCountries(),
        'dataRiskRating':this._countryService.getRiskRatingCountries()}).subscribe(result => {
          this.countriesList = result.dataCountry;
          this.riskRatingList = result.dataRiskRating;
          const mappedRefByRiskRatingCriteria = NextArrayHelper.indexArrayByProperty(this.riskRatingList, RiskRatingsProps.RISK_RATING_CRITERIA);
          this.countriesList = this.countriesList.map((country: any) => {
            const dataCountry = {
              ...country,
              [CountryProps.FDI_COUNTRY_LIMIT]: mappedRefByRiskRatingCriteria[country.riskCode].fdiCountryLimit,
              [CountryProps.FDI_COUNTRY_CURRENCY]: mappedRefByRiskRatingCriteria[country.riskCode].fdiCountryCurrency,
              [CountryProps.RISK_RATING_DESCRIPTION]: mappedRefByRiskRatingCriteria[country.riskCode].riskRatingDescription,
              [GenericTableColumnProps.MENU_LIST]: this.buildMenu(country)
            };

            return dataCountry;

        });
        this._createDropdownRiskRating();
        this._defineData();
        this._cdr.markForCheck();
        this.setDownloadExcel();
      });
    }
  }
  buildMenu(country: any){
    if(country.maintenanceTask) {
      return this._menuItemService.buildMenuItemList([CountryAction.GO_TO_TASK_BOARD], {
        [CountryAction.GO_TO_TASK_BOARD]: {
          label: 'COUNTRY.OPTIONS.GO_TO_TASK_BOARD',
          icon: NextIcon.EYE_OPENED,
          callback: () => {
            this._navigationService.navigateToRouteConfig(
              TaskBoardRouteConfig.generalMaintenanceViewDetails,
              {
                urlParams: { [RouteParams.ID]: country.maintenanceTask.id }
              }
            )
            .then();
          }
        }
      })
    } else {
      return this._menuItemService.buildMenuItemList([CountryAction.MODIFY], {
        [CountryAction.MODIFY]: {
          label: 'COUNTRY.OPTIONS.MODIFY',
          icon: NextIcon.EDIT,
          callback: () => {
            this.viewPopup(country);
          }
        }
      })
    }
    
  }
  updateCountry(country: any) {
    const id = country.form.code;
    this._countryService.updateCountry(id, country.form, country.selectedCountry).subscribe((response) => {
      const index = this.countriesList.findIndex((country: any) => country[CountryProps.CODE] === response[CountryEditProps.AFFECTED_RESOURCE_ID]);
      if (index >= 0) {
        this.countriesList[index].maintenanceTask = {
          id : response[CountryEditProps.ID],
          affectedResourceId: response[CountryEditProps.AFFECTED_RESOURCE_ID],
          status: response[CountryEditProps.STATUS]
        }
        this.countriesList[index][GenericTableColumnProps.MENU_LIST] = this.buildMenu(this.countriesList[index])
      }
      
      this.activePopup = false;
      this._cdr.markForCheck();
      
      this.selectedCountry = [];
    });
  }

  viewPopup(country: any) {
    this.selectedCountry = country;
    this.activePopup = true;
  }

  dismissPopup() {
    this.selectedCountry = [];
    this.activePopup = false;
  }

  pageChangeHandle(event: TablePageEvent) {
    this.handlePaginationChange(event);
  }

  private _defineData() {
    this._convertCountriesToEuMember();
  }

  private _createDropdownRiskRating() {
    this.countriesRiskRatingDropdown = this._countryService.createDropdownRiskRatingList(this.riskRatingList);
  }

  private _convertCountriesToEuMember() {
    const str1 = this._translateService.instant('COUNTRY.FIELDS.EU');
    const str2 = this._translateService.instant('COUNTRY.FIELDS.NON_EU');
    this.countriesList = NextBooleanHelper.convertBooleanToString(str1, str2, CountryProps.EURO_INDICATOR, this.countriesList);
  }

  private setDownloadExcel() {
    this._downloadService.setExcelType(CountryConstant.fileType);
  }

}
